<template>
  <b-modal
    id='effective-protection-modal'
    size='xl'
    ref='effective-protection-modal'
    hide-footer
    hide-header
    @show='showModal'
    @hidden='hideModal()'
  >
    <!--Modal de quando marca Sim para a pergunda 1:
    Existe algum EQUIPAMENTO DE PROTEÇÃO COLETIVA para auxiliar no controle de risco químico?-->
    <b-modal
      size='xl'
      ref='simPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico'
      id='simPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico'
      scrollable
      hide-footer
      hide-header
    >
      <section class='effectiveProtection'>
        <section class='mb-1'>
          <p class='mt-1 title_internal '>
            Existe algum EQUIPAMENTO DE PROTEÇÃO COLETIVA para auxiliar no controle de risco químico?
          </p>
        </section>

        <section class='divider'></section>

        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              O EPC esta funcionando adequadamente?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_1_1_OEPCFuncionandoAdequadamente'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> Um EPC que funciona adequadamente é aquele que apresenta um
                bom estado de conservação, que liga e desliga conforme o programado, que durante seu
                funcionamento aparenta estar com a potência esperada, que não tenha ruídos mecânicos
                provenientes de excentricidades ou falhas mecânicas e que aparente ser suficiente para
                as necessidades do ambiente de trabalho e da exposição do trabalhador. Essa análise pode
                ser um pouco subjetiva, mas o propósito desta pergunta é trazer uma breve percepção do
                ambiente de trabalho e as possíveis proteções advindas do EPC.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_1_1_OEPCFuncionandoAdequadamente'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_1_1_OEPCFuncionandoAdequadamente'
            v-model='protecaoEficaz.pergunta_1_1_OEPCFuncionandoAdequadamente'
          >
            <b-form-radio
              class='py-1 mr-5 '
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_1_1_OEPCFuncionandoAdequadamente ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-1 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_1_1_OEPCFuncionandoAdequadamente === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>

        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              Existe alguma comprovação de que o EPC é suficiente para controlar a quantidade de agentes químicos
              presentes no ambiente de trabalho?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> Esse tipo de comprovação pode ser advinda de um estudo de engenharia ou de
                fabricação do
                EPC que comprova a partir do layout do ambiente, do exaustor, das trocas de ar com o ambiente, da vazão
                do
                exaustor ou ainda de quaisquer outras qualidades inerentes à natureza e funcionamento do EPC.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia'
            v-model='protecaoEficaz.pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia'
          >
            <b-form-radio
              class='py-1 mr-5 '
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-1 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>

        <section class='my-2'>
          <section class='d-flex align-items-start'>
            <label>
              Existe alguma comprovação ou evidência de que a exposição do trabalhador aos agentes nocivos foi
              eliminada?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> Comprovações ou evidências de controle da exposição de agentes químicos
                através de EPC podem ser estudos qualitativos ou quantitativos. No caso dos qualitativos podemos
                evidenciar que a exposição foi controlada através da análise de metodologias como VHR (Vapor Hazard
                Ratio),
                Control Banding e outras, entretanto a força ou comprovação através desse tipo de análise costuma ser
                questionável devido às limitações dos métodos de análise. Já no caso de análises quantitativas temos
                as quantificações de agentes químicos que são feitas esperando resultados de amostragem extremamente
                baixos (perto do limite de quantificação do método analítico) a fim evidenciar que a exposição foi
                controlada.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao'
            v-model='protecaoEficaz.pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao'
          >
            <b-form-radio
              class='py-1 mr-5 '
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-1 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>

        <section class='divider'></section>

        <section class='mt-3 float-right'>
          <b-button
            class='px-4'
            variant='success'
            @click='fecharModalSimPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico()'
          >
            Ok
          </b-button>
        </section>
      </section>
    </b-modal>


    <!--Modal de quando marca Sim para a pergunda 2:
    O empregador ou organização cumpre e faz cumprir todos os itens da NR 06 em seu tópico 6.5?-->
    <b-modal
      size='xl'
      ref='simPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico'
      id='simPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico'
      scrollable
      hide-footer
      hide-header
    >
      <section class='effectiveProtection mb-5'>
        <section class='mb-1'>
          <p class='mt-1 title_internal '>
            O empregador ou organização cumpre e faz cumprir todos os itens da NR 06 em seu tópico 6.5?
          </p>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-center'>
            <label>
              Todos os EPIs que a empresa adquiriu possuem CA (Certificado de aprovação)?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: adquirir somente o
                aprovado pelo órgão de âmbito nacional competente
                em matéria de segurança e saúde no trabalho]
                O EPI deve possuir CA (certificado de aprovação).
                É importante também citar 3 itens alinhados à prazos sobre o EPI
              </p>

              <ul class='ml-5'>
                <li class='mb-1'>
                  Tempo de garantia: A garantia do calçado normalmente é contra defeito de fábrica. Dessa forma é
                  determinada por quem produz o EPI. Esse tempo é contado a partir de sua data de fabricação.
                </li>

                <li class='mb-1'>
                  Prazo de validade do CA: A validade do CA é de 5 anos - período em que pode ser fabricado e
                  comercializado.
                </li>

                <li class='mb-1'>
                  Prazo de validade do EPI: A validade do EPI é determinada pelo fabricante. Trata-se de uma estimativa
                  de tempo em que pode ser utilizado sem diminuição de proteção, desde que usado no ambiente ao qual é
                  destinado.
                </li>
              </ul>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA'
            v-model='protecaoEficaz.pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-center'>
            <label>
              Os empregados foram orientados e treinados sobre o uso de EPI?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: orientar e treinar o empregado]
                Aqui temos diversas situações as quais o empregador deve preparar seus empregados quando houver o uso de
                EPI, mas entre essas cabe citarmos as mais comuns:
              </p>

              <ul class='ml-5 pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI'>
                <li class='mb-1'>
                  Situações em que o empregado deve cumprir para que o EPI não seja utilizado de forma inadequada: o uso
                  de um protetor facial pode exigir que o trabalhador não tenha barba, mas se o trabalhador não souber
                  dessa informação e mesmo assim estiver utilizando o EPI é possível dizer que a minimização do risco
                  não está sendo alcançada.
                </li>

                <li class='mb-1'>
                  Situações em que o empregado deve saber onde receber e devolver o EPI: a cada início e término de
                  ciclo de trabalho ou jornada o trabalhador deve saber onde pegar e guardar o EPI. Indo mais a fundo
                  ele também deve saber o que fazer caso o EPI apresente alguma avaria e para isso ele precisa receber
                  instrução do que seria um EPI “íntegro”.
                </li>

                <li class='mb-1'>
                  Explicar e conscientizar o empregado sobre os riscos da função e fazendo-o entender que o uso do EPI
                  fará com que ele esteja mais seguro.
                </li>
              </ul>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI'
            v-model='protecaoEficaz.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              O EPI fornecido foi gratuito ao empregado, assim como no momento do fornecimento estava em perfeito estado
              de conservação e de forma adequada ao risco?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: Fornecer ao empregado de forma gratuita um EPI adequado
                ao risco, em perfeito estado de conservação e funcionamento, nas situações previstas no subitem
                1.5.5.1.2 da Norma Regulamentadora no 01 (NR-01) - Disposições Gerais e Gerenciamento de Riscos
                Ocupacionais, observada a hierarquia das medidas de prevenção]
                A NR 01 em seu subitem 1.5.5.1.2 informa que a adoção do EPI é a última escolha a fim de minimizar a
                exposição ao risco. Para facilitar o entendimento desta orientação anexamos o referido subitem da norma:
                “1.5.5.1.2 Quando comprovada pela organização a inviabilidade técnica da adoção de medidas de proteção
                coletiva, ou quando estas não forem suficientes ou encontrarem-se em fase de estudo, planejamento ou
                implantação ou, ainda, em caráter complementar ou emergencial, deverão ser adotadas outras medidas,
                obedecendo-se a seguinte hierarquia:<br />
                a) medidas de caráter administrativo ou de organização do trabalho; e<br />
                b) utilização de equipamento de proteção individual - EPI.<br />
              </p>

              <p>
                Além disso é importante que: 6.5.2 A organização deve selecionar os EPI, considerando:
              </p>

              <ul
                class='ml-5 pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco'>
                <li class='mb-1'>
                  A atividade exercida;
                </li>

                <li class='mb-1'>
                  As medidas de prevenção em função dos perigos identificados e dos riscos ocupacionais avaliados;
                </li>

                <li class='mb-1'>
                  O disposto no Anexo I (o anexo que informa o que é considerado EPI segundo a NR 06);
                </li>

                <li class='mb-1'>
                  A eficácia necessária para o controle da exposição ao risco;
                </li>

                <li class='mb-1'>
                  As exigências estabelecidas em normas regulamentadoras e nos dispositivos legais;
                </li>

                <li class='mb-1'>
                  A adequação do equipamento ao empregado e o conforto oferecido, segundo avaliação do conjunto de
                  empregados;
                </li>

                <li class='mb-1'>
                  A compatibilidade, em casos que exijam a utilização simultânea de vários EPI, de maneira a assegurar
                  as respectivas eficácias para proteção contra os riscos existentes.
                </li>
              </ul>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco'
            v-model='protecaoEficaz.pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              O fornecimento do EPI foi e é sempre registrado?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_2_4_fornecimentoEPISempreRegistrado'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: registrar o seu fornecimento ao empregado, podendo ser
                adotados livros, fichas ou sistema eletrônico, inclusive, por sistema biométrico]
              </p>

              <p>
                O empregador deve registrar o fornecimento dos EPIs aos trabalhadores podendo adotar para tal
                procedimento, livros, fichas ou meios eletrônicos. Atenção: a NR6 não contém nenhum modelo de formulário
                para o controle do fornecimento dos EPIs. A norma apenas exige que este controle seja feito pela
                empresa. É permitido, inclusive o controle por biometria. Em qualquer caso, o registro deste
                fornecimento deve estar sempre disponível para a fiscalização.
                Essa etapa da gestão do EPI por parte da empresa é importantíssima, pois o registro do EPI é um dos
                itens mais analisados em situações periciais e de fiscalização.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_2_4_fornecimentoEPISempreRegistrado'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_2_4_fornecimentoEPISempreRegistrado'
            v-model='protecaoEficaz.pergunta_2_4_fornecimentoEPISempreRegistrado'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_2_4_fornecimentoEPISempreRegistrado ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_2_4_fornecimentoEPISempreRegistrado === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              A empresa em caráter de fiscalização constante junto aos seus empregados está exigindo o uso do EPI?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: exigir seu uso]
                Não basta o empregador fornecer o EPI, o empregador também deve fiscalizar o uso. A fiscalização deste
                uso pode ser feita, dentre outras formas, através de ficha de campo assinalando a identificação do
                trabalhador, o EPI, a data e hora, o responsável pela fiscalização. Se possível, para fins de
                comprovação jurídica, recolher a assinatura do trabalhador é também muito importante.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI'
            v-model='protecaoEficaz.pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              A empresa executa e tem procedimentos internos para realizar a higienização e manutenção periódica dos
              EPIs?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: Responsabilizar-se pela higienização e manutenção
                periódica, quando aplicáveis esses procedimentos, em conformidade com as informações fornecidas pelo
                fabricante ou importador]
              </p>

              <p>
                A responsabilidade pela higienização e manutenção periódica do EPI é do empregador. É necessário haver
                cuidado para não confundir esta obrigação com a guarda e conservação do EPI, que são responsabilidades
                do empregado.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs'
            v-model='protecaoEficaz.pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              A empresa substitui <strong>imediatamente</strong> o EPI em caso dele estar danificado ou extraviado?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: substituir imediatamente, quando danificado ou
                extraviado]<br />
                Uma vez danificado, o EPI perde suas propriedades intrínsecas de proteção e deve ser substituído
                imediatamente. Para que esta substituição ocorra sem problemas, é importante que a empresa tenha EPIs em
                estoque como itens de reposição e que o setor responsável esteja "aberto" durante toda a jornada,
                inclusive nos turnos noturnos.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado'
            v-model='protecaoEficaz.pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              A empresa executa e tem procedimentos internos para caso o EPI apresente irregularidades?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: comunicar ao órgão de âmbito nacional competente em
                matéria de segurança e saúde no trabalho qualquer irregularidade observada]<br />
                Sempre que qualquer irregularidade for observada em algum EPI, por exemplo, trincas em capacete para
                proteção contra impactos de objetos sobre o crânio, o empregador deve comunicar este fato ao Ministério
                da Economia imediatamente, para que sejam tomadas as devidas providências, como por exemplo,
                recolhimento de amostras do EPI, e realização de novos testes. Dependendo da irregularidade, o EPI
                poderá até ter seu Certificado de Aprovação cancelado pela SIT/CGSST. Caberá a estes órgãos a apuração
                de irregularidades sobre os EPIs (que já estão no mercado), que forem comunicadas ao Ministério da
                Economia. Para fazer esta apuração devem ser recolhidas amostras dos produtos e realizados novos testes.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades'
            v-model='protecaoEficaz.pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='divider'></section>

        <section class='mt-3 float-right'>
          <b-button
            class='px-4'
            variant='success'
            @click='fecharModalSimPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico()'
          >
            Ok
          </b-button>
        </section>
        <br />
      </section>
    </b-modal>


    <!--Modal de quando marca Sim para a pergunda 3:
    O empregado ou trabalhador cumpre todos os itens da NR 06 em seu tópico 6.6?-->
    <b-modal
      size='xl'
      ref='simPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66'
      id='simPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66'
      scrollable
      hide-footer
      hide-header
    >
      <section class='effectiveProtection'>
        <section class='mb-1'>
          <p class='mt-1 title_internal '>
            O empregado ou trabalhador cumpre todos os itens da NR 06 em seu tópico 6.6?
          </p>
        </section>

        <section class='mt-2'>
          <section class='d-flex align-items-center'>
            <label>
              O EPI que o trabalhador usa é somente aquele fornecido pela empresa?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: usar o fornecido pela organização, observado o disposto
                no item 6.5.2 da NR 06]
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa'
            v-model='protecaoEficaz.pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-center'>
            <label>
              O EPI utilizado pelo trabalhador está alinhado com sua necessidade/finalidade/risco?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: utilizar apenas para a finalidade a que se destina]
                <br />
                O empregado é obrigado a usar o EPI somente para os fins a que se destina. O uso do EPI fora das
                finalidades especificadas pela organização traz um desgaste acelerado, assim como prejudica a gestão por
                parte da empresa.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco'
            v-model='protecaoEficaz.pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-center'>
            <label>
              O trabalhador está sendo responsável pela limpeza, guarda e conservação do EPI?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: responsabilizar-se pela limpeza, guarda e conservação]
                <br />
                Cada empregado deve ser responsável por guardar e conservar o EPI que utilizar. Mas para isto deverá ter
                recebido as devidas orientações e treinamento por parte do empregador.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI'
            v-model='protecaoEficaz.pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-start'>
            <label>
              O trabalhador matem boa comunicação com a empresa para informar situações em que o EPI estiver extraviado,
              danificado ou qualquer alteração que o torne impróprio para o uso?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: comunicar à organização quando extraviado, danificado ou
                qualquer alteração que o torne impróprio para uso]
                <br />
                O empregado tem a obrigação de comunicar ao empregador qualquer alteração que torne o seu EPI impróprio
                para uso por quaisquer motivos, por exemplo, desgaste ou eventuais danos.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso'
            v-model='protecaoEficaz.pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='mt-2'>
          <section class='d-flex align-items-center'>
            <label>
              O trabalhador cumpre as determinações da empresa quanto ao uso do EPI?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI'
              placement='auto'
              triggers='hover'
            >
              <p>
                <strong>Orientação:</strong> [trecho da norma: cumprir as determinações da organização sobre o uso
                adequado]
                <br />
                O empregado também deve receber treinamento sobre os procedimentos de uso adequado do EPI. Por exemplo,
                em alguns casos, os calçados para proteção dos pés e das pernas contra agentes químicos devem ser usados
                com meias de algodão de cano longo de forma a evitar atrito com os pés, tornozelos e canelas. As bocas
                da calça não devem ser colocadas para dentro da bota, e sim para fora, como forma de impedir que algum
                produto caia para o interior do calçado e entre em contato com a pele do trabalhador (este é o caso, por
                exemplo, de trabalhadores da construção civil). A colocação correta dos protetores auditivos e das
                máscaras de proteção respiratória (incluindo a realização dos testes de vedação) é condição para a
                proteção adequada.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updateEffectiveprotection()'
            class='justify-content-between'
            name='pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI'
            v-model='protecaoEficaz.pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="protecaoEficaz.pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="protecaoEficaz.pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>


        <section class='divider'></section>

        <section class='mt-3 float-right'>
          <b-button
            class='px-4'
            variant='success'
            @click='fecharModalSimPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66()'
          >
            Ok
          </b-button>
        </section>
      </section>
    </b-modal>


    <!--Modal de Orientação atualizada sobre exposição permanente-->
    <b-modal
      size='xl'
      ref='guidanceOnTopic'
      id='guidanceOnTopic'
      scrollable
      hide-footer
      hide-header
    >
      <section class='guidanceOnTopic'>
        <section class='mb-3 d-flex'>
          <a @click='hideQuidanceOnTopic()'>
            <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 24 24' fill='none'>
              <path d='M9 14L5 10L9 6' stroke='#022245' stroke-width='2' stroke-linecap='round'
                    stroke-linejoin='round' />
              <path
                d='M5 10H16C17.0609 10 18.0783 10.4214 18.8284 11.1716C19.5786 11.9217 20 12.9391 20 14C20 15.0609 19.5786 16.0783 18.8284 16.8284C18.0783 17.5786 17.0609 18 16 18H15'
                stroke='#022245' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
            </svg>
          </a>
          <p class='title ml-3'>
            Orientação atualizada sobre exposição permanente
          </p>
        </section>
        <p>
          A necessidade da exposição permanente para fins de enquadramento dos agentes químicos no LTCAT é mencionada,
          entre outras,
          no Decreto 3.048, de 6 de maio de 1999 no seu Art. 68. Para tanto o conceito mais atual sobre o que é uma
          exposição
          permanente vem através da INSTRUÇÃO NORMATIVA PRES/INSS No 128, DE 28 DE MARÇO DE 2022:
        </p>

        <p>
          <i>
            "Art. 268. Para fins de concessão de aposentadoria especial, será exigida a comprovação do exercício da
            atividade
            de forma permanente,
          </i>

          <span>
            entendendo-se como <strong>permanente o trabalho não ocasional nem intermitente, no qual a efetiva
            exposição do trabalhador ao agente prejudicial à saúde é indissociável da produção do bem ou da prestação de
            serviço, exercida em condições especiais que prejudiquem a saúde, </strong>
          </span>
          <i>
            durante o período de 15 (quinze), 20 (vinte) ou 25 (vinte e cinco) anos." (grifos nossos)
          </i>
        </p>

        <b-tooltip target='paragrafo_01' placement='auto' triggers='hover'>
          <p class='text-center'>
            <strong>
              BATISTA, Homero. Capítulo 3. Sobre a Habitualidade da Prestação de Serviços Em: Batista,
              Homero. Direito do Trabalho Aplicado: Teoria Geral de Direito do Trabalho e do Direito Sindical.
              São Paulo (SP): Editora Revista dos Tribunais. 2021.
            </strong>
          </p>
        </b-tooltip>

        <p id='paragrafo_01'>
          A partir das marcações acima, destaca-se que:<br />
          1) Para o grifo "<span><strong>permanente o trabalho não ocasional nem intermitente</strong></span>": "A noção
          de permanência tem que ver com a repetição ou
          a expectativa da repetição e não propriamente com a duração, em número de horas, dessa exposição.
          A noção de habitualidade, palavra que preferimos às expressões ineventualidade ou não eventualidade,
          viceja muito mais na repetição razoavelmente esperada do que propriamente no número de dias da semana
          ou no número de horas laboradas.
          Se fosse assim, não haveria nem sequer o contrato de trabalho a tempo parcial nem se poderia falar em trabalho
          do médico plantonista, pois esses e outros casos teriam carga horária muito baixa quando comparados aos
          empregados do
          sistema integral de jornada.<br />

          O que prevalece, num ou noutro caso, é a noção do trato sucessivo, no sentido de que aquelas mesmas condições
          de
          trabalho e de contraprestação se repitam sucessivamente, sem maior alarde ou necessidade de repactuação a
          todo instante (e, adianto desde logo, o contrato de trabalho intermitente, inserido no Brasil pela reforma
          trabalhista de 2017, será também ele considerado no plano da habitualidade, ainda que o empregado seja
          acionado pouquíssimas vezes).
          <br />
          Expectativa é a palavra-chave." (Batista, 2021)
        </p>

        <p>
          2) Para o grifo "<span><strong>efetiva exposição</strong></span>": a exposição a qual o trabalhador de fato
          tem contato com o agente químico, seja ela
          (a exposição) via dermal ou respiratória, independentemente da forma como o agente químico se apresenta no
          ambiente.
          (HO Fácil Web, 2024)
        </p>

        <p>
          3) Para o grifo "<span><strong>indissociável da produção do bem ou da prestação de serviço</strong></span>":
          toda exposição com o agente químico
          que obrigatoriamente acontece durante a atividade, ou seja, é necessária e faz parte para que a atividade seja
          executada, é considerada como indissociável. (HO Fácil Web, 2024)
        </p>

        <b-tooltip target='paragrafo_02' placement='auto' triggers='hover'>
          <a href='https://drive.google.com/file/d/14xgFajBx6Ob_bsDhi5d0AJ4nsBQWMu2q/view?usp=sharing' target='_blank'>
            <p>Clique aqui para acessar a nota técnica</p>
          </a>
        </b-tooltip>

        <section id='paragrafo_02'>
          <p>
            4) Para o grifo "<span><strong>condições especiais que prejudiquem a saúde</strong></span>": Em 2022 a
            Fundacentro emitiu uma nota técnica a qual sugere um
            passo a passo para reconhecer o potencial nocivo (condições que prejudiquem a saúde) do trabalhador. Essa
            orientação da
            Fundacentro encontra-se no tópico 2.10 (link para acesso da nota técnica).
            Destaca-se ainda que o HO Fácil Web traz para muitos agentes químicos informações prontas de forma que o
            usuário não
            precise interagir para reconhecer o potencial nocivo do risco. <br />

            Menciona-se também dois conceitos importantes para a base de argumentação sobre o potencial nocivo dos
            agentes químicos: <br />
          </p>
          <ul class='ml-3'>
            <li>
              <i>"Todas as substâncias, naturais ou sintéticas são potencialmente tóxicas; em outras palavras, podem
                produzir efeitos adversos
                para a saúde em alguma condição de exposição. É incorreto denominar algumas substâncias químicas como
                tóxicas e outras como
                não tóxicas. As substâncias diferem muito na toxicidade. As condições de exposição e a dose são fatores
                que determinam os
                efeitos tóxicos." </i> (Ottoboni, 1991)
            </li>

            <li>
              <i>“Todas as substâncias são tóxicas. Não há nenhuma que não seja tóxica. A dose estabelece a diferença
                entre um tóxico e um medicamento”. </i>
              (Frase atribuída a Paracelso, médico no século XVI e considerado o pai da toxicologia)
            </li>
          </ul>
        </section>
      </section>
    </b-modal>


    <!--Modal de Orientação atualizada sobre proteção eficaz-->
    <b-modal
      size='xl'
      ref='effectiveProtectionQuidance'
      id='effectiveProtectionQuidance'
      scrollable
      hide-footer
      hide-header
    >
      <section class='guidanceOnTopic'>
        <section class='mb-1 d-flex'>
          <a @click='hideEffectiveProtectionQuidance()'>
            <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 24 24' fill='none'>
              <path d='M9 14L5 10L9 6' stroke='#022245' stroke-width='2' stroke-linecap='round'
                    stroke-linejoin='round' />
              <path
                d='M5 10H16C17.0609 10 18.0783 10.4214 18.8284 11.1716C19.5786 11.9217 20 12.9391 20 14C20 15.0609 19.5786 16.0783 18.8284 16.8284C18.0783 17.5786 17.0609 18 16 18H15'
                stroke='#022245' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
            </svg>
          </a>
          <p class='title ml-3 '>
            Orientação atualizada sobre proteção eficaz
          </p>
        </section>

        <h5 class='text-center font-weight-bold mb-3'>
          QUANDO O EPI É CONSIDERADO EFICAZ SEGUNDO A NR 6
        </h5>

        <p id='paragrafo_01'>
          <strong>O EPI não elimina o risco</strong>, mas tão somente neutraliza ou minimiza a exposição ao agente
          insalubre. Sendo assim,
          para fins de enquadramento de adicional de insalubridade conforme a NR 15 há de se relacionar os critérios
          previstos na NR 6 quanto ao uso de EPI,
          pois se houver a atenção às obrigações do empregado e do empregador à exposição ao agente insalubre se dará
          como neutralizada, e,
          portanto, não haverá a exposição necessária para ocorrer o ensejando ao adicional de insalubridade.
        </p>

        <p>
          Para que o EPI seja considerado eficaz, um dos requisitos mínimos é que o empregador cumpra seu papel
          em fornecer condições salubres de trabalho, seja pelo ambiente de trabalho ou então diretamente através
          da relação do uso adequado do EPI junto ao processo de trabalho.
        </p>

        <p>
          A seguir serão abordadas as obrigações do empregado e do empregador conforme a NR 6,
          sendo que para cada item terão observações, se necessário, para exemplificar condutas adequadas para o seu
          cumprimento.
        </p>

        <section id='paragrafo_02'>
          <p>
            Além das relações de obrigações do empregador dispostas no Art. 157 da CLT,
            cabe também um conceito muito importante trazido pela NR 1: cumprir e fazer cumprir.
          </p>

          <p>
            A obrigação de cumprir e fazer cumprir está direcionada às disposições legais e regulamentares sobre SST,
            onde cabe ao empregador:
          </p>

          <ul class='ml-5'>
            <li class='mb-1'>
              Não somente cumprir as leis e normas regulamentadoras referentes à segurança e saúde no trabalho,
              mas também exigir seu cumprimento pelos trabalhadores, por terceiros e pelos visitantes também;
            </li>

            <li class='mb-1'>
              Informar aos trabalhadores sobre os riscos ocupacionais existentes no local de trabalho;
            </li>

            <li class='mb-1'>
              Informar aos trabalhadores os resultados das avaliações ambientais realizadas nos locais de trabalho;
            </li>

            <li class='mb-1'>
              Informar aos trabalhadores as medidas de prevenção adotadas pela empresa para eliminar ou reduzir tais
              riscos.
            </li>
          </ul>

          <p>
            As informações sobre os itens da norma que perguntamos contém orientações pontuais para facilitar o
            entendimento.
          </p>
        </section>

        <p>
          Além dessas informações buscamos trazer deixaremos a recomendação da leitura da NR 01, NR 06:
        </p>

        <p>
          <a href='https://drive.google.com/file/d/1OKG5BbkDrkaBLe_zjlRrhwAoQ04jqlk_/view' target='_blank'>
            NR 01 - DISPOSIÇÕES GERAIS e GERENCIAMENTO DE RISCOS OCUPACIONAIS
          </a>
        </p>

        <p>
          <a href='https://drive.google.com/file/d/16EDnxg-twHErZasao98Pbr-acevBvq-u/view' target='_blank'>
            NR 06 - EQUIPAMENTOS DE PROTEÇÃO INDIVIDUAL – EPI
          </a>
        </p>

      </section>
    </b-modal>

    <section class='effectiveProtection'>
      <section class='text-center mb-4 position-relative' v-if='functionality === home'>
        <h1 class='mt-1'>Ferramentas de análise</h1>

        <a
          @click='hideEffectiveProtection()'
          class='d-flex align-items-center position-absolute float-right btn_close mb-1'
          style='top: -7px!important;'
        >
          <b-icon
            class='ml-1 '
            font-scale='1.8'
            icon='x'
            color='#022245'
          >
          </b-icon>
        </a>
      </section>

      <section class='w-100 d-flex' v-if='functionality === home'>
        <section class='w-50 border-right pr-4'>
          <section class='mb-1 text-center'>
            <p class='title '>Exposição Permanente</p>
          </section>

          <section class='mt-5 d-flex justify-content-center'>
            <b-button
              class='px-3 d-flex align-items-center'
              variant='success'
              @click="setFunctionalities('questionsPermanentExhibition')"
            >
              <b-icon
                font-scale='.999'
                icon='pencil-square'
                class='mr-2'
              >
              </b-icon>
              <span>Clique aqui para preencher ou editar</span>
            </b-button>
          </section>

          <section class='mt-5 text-center'>
            <p>
              Vamos te orientar através de algumas perguntas se a exposição em que o trabalhador está pode ser
              considerada como
              uma exposição permanente para fins de enquadramento no LTCAT.
            </p>
          </section>
        </section>

        <section class='w-50 pl-4'>
          <section class='mb-1 text-center'>
            <p class='title'>Proteção Eficaz</p>

          </section>

          <section class='mt-5 d-flex justify-content-center'>
            <b-button
              class='px-3 d-flex align-items-center'
              variant='success'
              @click="setFunctionalities('questionsEffectiveProtection')"
            >
              <b-icon
                font-scale='.999'
                icon='pencil-square'
                class='mr-2'
              >
              </b-icon>
              <span>Clique aqui para preencher ou editar</span>
            </b-button>
          </section>

          <section class='mt-5 text-center'>
            <p>
              Vamos te orientar através algumas perguntas sobre equipamentos de proteção se as ações desempenhadas pelo
              empregador e seus empregados estão sugerindo uma proteção eficaz.
            </p>
          </section>
        </section>
      </section>

      <section class='w-100 pr-4' v-else-if='functionality === questionsPermanentExhibition'>
        <section class=''>
          <section class='mb-1 text-center position-relative'>
            <p class='title '>Exposição Permanente</p>
            <a
              @click="hideEffectiveProtection()"
              class='d-flex align-items-center position-absolute float-right btn_close'
            >
              <b-icon
                class='ml-1 '
                font-scale='1.5'
                icon='x'
                color='#022245'
              >
              </b-icon>
            </a>
          </section>

          <p class='subtitle' @click='showQuidanceOnTopic()'>
            As opção abaixo foram criadas a partir de um entendimento técnico e jurídico que tem por finalidade
            simplificar o conceito de exposição permamente.
            <a class=''>
              Clique aqui para ler a nossa orientação sobre o tema.
            </a>
          </p>
        </section>

        <section>
          <section class='d-flex align-items-center'>
            <label>
              A frequência em que o trabalhador realiza a atividade é previsível?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade'
              triggers='hover' placement='auto'
            >
              <p>
                <strong>Orientação:</strong>
                A frequência é previsível quando o trabalhador realiza a atividade de forma contínua, habitual ou
                rotineira.
                Uma frequência previsível não precisa ser todos os dias em uma grande carga horária diária.
                Desde que seja sabido que o trabalhador costuma realizar a atividade já é suficiente para
                saber que a frequência é previsível.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updatePermanentExhibition()'
            class='justify-content-between'
            name='frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade'
            v-model='exposicaoPermanente.frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="exposicaoPermanente.frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="exposicaoPermanente.frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>

        <section>
          <section class='d-flex align-items-start'>
            <label>
              Quando o trabalhador realiza a atividade ele tem a possibilidade de não utilizar os produtos químicos?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos'
              triggers='hover'
              placement='auto'
            >
              <p>
                <strong>Orientação</strong>
                Existem situações em que o trabalhador pode realizar a atividade sem a necessidade do emprego dos
                produtos químicos.
                Nesses casos é entendido que a exposição nociva aos agentes químicos não é obrigatória e, portanto, não
                seria
                indissociável da atividade. Para facilitar o entendimento desta situação pense em um jardineiro que para
                a
                execução da sua atividade ele tenha a opção de utilizar um agrotóxico: como o emprego do agrotóxico é
                opcional,
                então não estaríamos diante de uma situação em que a exposição é indissociável. Entretanto caso o
                agrotóxico seja
                necessário para a execução de sua atividade, então estaremos diante de uma situação em que o trabalhador
                estará exposto
                de potencialmente de forma nociva aos agentes químicos do agrotóxico e, sendo assim, a atividade e a
                exposição do
                trabalhador são indissociáveis.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updatePermanentExhibition()'
            class='justify-content-between'
            name='trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos'
            v-model='exposicaoPermanente.trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
                    <span
                      :class="exposicaoPermanente.trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos ? 'selected': 'not-selected'"
                    >
                      Sim
                    </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
                    <span
                      :class="exposicaoPermanente.trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos === false ? 'selected': 'not-selected'"
                    >
                      Não
                    </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>

        <section>
          <section class='d-flex align-items-start'>
            <label>
              O trabalhador está exposto aos agentes químicos de forma dermal ou respiratória?
            </label>

            <b-tooltip
              custom-class='custom-tooltip'
              target='trabalhadorexecutaAtividadeAgentesQuimicosAnalisados'
              triggers='hover'
              placement='auto'
            >
              <p>
                <strong>Orientação:</strong>
                A exposição dermal é quando o trabalhador está em contato com os agentes químicos através da pele.
                A exposição respiratória/inalatória é quando o trabalhador está em contato com os agentes químicos
                através da respiração. Uma dica para analisar a exposição dermal é olhar se o posto do trabalho ou o
                próprio trabalhador estão em uma condição de sujidade, ou seja, o trabalhador está com a pele suja de
                agentes químicos. Uma dica para analisar a exposição respiratória/inalatória é olhar se o posto do
                trabalho está com uma concentração de agentes químicos dispersos no ar ou acumulados nas superfícies
                e/ou no chão. É importante se atentar que o fato de não sentirmos um cheiro de produto químico no
                ambiente não significa que não exista uma concentração de agentes químicos no ambiente.
              </p>
            </b-tooltip>

            <b-icon
              style='cursor: pointer; color: white'
              class='ml-1 mb-2'
              font-scale='1.3'
              icon='question-circle-fill'
              id='trabalhadorexecutaAtividadeAgentesQuimicosAnalisados'
              variant='info'>
            </b-icon>
          </section>

          <b-form-radio-group
            :disabled='disabled'
            @change='updatePermanentExhibition()'
            class='justify-content-between'
            name='trabalhadorexecutaAtividadeAgentesQuimicosAnalisados'
            v-model='exposicaoPermanente.trabalhadorexecutaAtividadeAgentesQuimicosAnalisados'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <span
                :class="exposicaoPermanente.trabalhadorexecutaAtividadeAgentesQuimicosAnalisados ? 'selected': 'not-selected'"
              >
                Sim
              </span>
            </b-form-radio>

            <b-form-radio
              class='py-2 mr-5'
              :value='false'
            >
              <span
                :class="exposicaoPermanente.trabalhadorexecutaAtividadeAgentesQuimicosAnalisados === false ? 'selected': 'not-selected'"
              >
                Não
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </section>

        <section class='d-flex mt-2 align-items-center justify-content-end'>
          <b-button
            @click='resetPermanentExhibition()'
            variant='danger'
            class='px-3 mr-3 d-flex align-items-center'
          >
            <span class='label'>Limpar as respostas que foram selecionadas</span>
          </b-button>

          <b-button
            class='px-3 d-flex align-items-center'
            variant='success'
            @click="hideEffectiveProtection()"
          >
            <i class='fa fa-save'></i>
            <strong class='ml-2'>Salvar</strong>
          </b-button>
        </section>
      </section>

      <section class='w-100 pl-4' v-else-if='functionality === questionsEffectiveProtection'>
        <section>
          <section class='mb-1 text-center position-relative'>
            <p class='title'>Proteção Eficaz</p>

            <a
              @click="hideEffectiveProtection()"
              class='d-flex align-items-center position-absolute float-right btn_close'
            >

              <b-icon
                class='ml-1 '
                font-scale='1.5'
                icon='x'
                color='#022245'
              >
              </b-icon>
            </a>
          </section>

          <p class='subtitle'>
            As opções abaixo foram criadas a partir de um entendimento técnico e jurídico que tem por finalidade te
            auxiliar no reconhecimento de uma proteção eficaz.
            A análise como um todo sobre a proteção eficaz é complexa e tem muitos itens a serem avaliados além das
            perguntas que fazemos
            <a @click='showEffectiveProtectionQuidance()'>
              Clique aqui para ler a nossa orientação sobre o tema.
            </a>
          </p>
        </section>

        <section>
          <section>
            <label>
              Existe algum EQUIPAMENTO DE PROTEÇÃO COLETIVA para auxiliar no controle de risco químico?
            </label>

            <b-form-radio-group
              :disabled='disabled'
              @change='updateEffectiveprotection()'
              class='justify-content-between'
              name='pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico'
              v-model='protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico'
            >
              <b-form-radio
                class='py-2 mr-5 '
                :value='true'
              >
                <span
                  :class="protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico ? 'selected': 'not-selected'"
                >
                  Sim
                </span>
              </b-form-radio>

              <b-form-radio
                class='py-2 mr-5'
                :value='false'
              >
                <span
                  :class="protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico === false ? 'selected': 'not-selected'"
                >
                  Não
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </section>

          <section
            class='mb-5'
            v-if='protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico'
          >
            <section class=' pt-2'>
              <label>O EPC esta funcionando adequadamente?:
                <strong>
                  {{
                    protecaoEficaz.pergunta_1_1_OEPCFuncionandoAdequadamente === null ? '-' : protecaoEficaz.pergunta_1_1_OEPCFuncionandoAdequadamente ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>

            <section class='pt-2'>
              <label>Existe alguma comprovação de que o EPC é suficiente para controlar a quantidade de agentes químicos
                presentes no ambiente de trabalho?:
                <strong>
                  {{
                    protecaoEficaz.pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia === null ? '-' : protecaoEficaz.pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>

            <section class='pt-2'>
              <label>Existe alguma comprovação ou evidência de que a exposição do trabalhador aos agentes nocivos foi
                eliminada?:
                <strong>
                  {{
                    protecaoEficaz.pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao === null ? '-' : protecaoEficaz.pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>

            <section class='d-flex justify-content-end mb-3'>
              <b-button
                class='px-3 d-flex align-items-center'
                variant='success'
                @click='mostrarModalSimPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico()'
              >
                <b-icon
                  font-scale='.999'
                  icon='pencil-square'
                  class='mr-2'
                >
                </b-icon>
                <span>Editar</span>
              </b-button>
            </section>

            <section class='divider'></section>
          </section>
        </section>

        <section>
          <section>
            <label>
              O empregador ou organização cumpre e faz cumprir todos os itens da NR 06 em seu tópico 6.5?
            </label>

            <b-form-radio-group
              :disabled='disabled'
              @change='updateEffectiveprotection()'
              class='justify-content-between'
              name='pergunta_2_EempregadorOrganizacaoCumpreFazCumprirItensNR06Topico'
              v-model='protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico'
            >
              <b-form-radio
                class='py-2 mr-5 '
                :value='true'
              >
                <span
                  :class="protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico ? 'selected': 'not-selected'"
                >
                  Sim
                </span>
              </b-form-radio>

              <b-form-radio
                class='py-2 mr-5'
                :value='false'
              >
                <span
                  :class="protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico === false ? 'selected': 'not-selected'"
                >
                  Não
                </span>
              </b-form-radio>

              <b-form-radio
                class='py-2 mr-5'
                :value='3'
              >
                <span
                  :class="protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico == 3 ? 'selected': 'not-selected'"
                >
                  Não se aplica
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </section>

          <section
            class='mb-5'
            v-if='protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico == true'
          >
            <section class=' pt-2'>
              <label>Todos os EPIs que a empresa adquiriu possuem CA (Certificado de aprovação)?:
                <strong>
                  {{
                    protecaoEficaz.pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA === null ?
                      '-' : protecaoEficaz.pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>Os empregados foram orientados e treinados sobre o uso de EPI?:
                <strong>
                  {{
                    protecaoEficaz.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI === null ?
                      '-' : protecaoEficaz.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                O EPI fornecido foi gratuito ao empregado, assim como no momento do fornecimento estava em perfeito
                estado de conservação e de forma adequada ao risco?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco === null ?
                      '-' : protecaoEficaz.pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                O fornecimento do EPI foi e é sempre registrado?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_2_4_fornecimentoEPISempreRegistrado === null ?
                      '-' : protecaoEficaz.pergunta_2_4_fornecimentoEPISempreRegistrado ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                A empresa em caráter de fiscalização constante junto aos seus empregados está exigindo o uso do EPI?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI === null ?
                      '-' : protecaoEficaz.pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                A empresa executa e tem procedimentos internos para realizar a higienização e manutenção periódica dos
                EPIs?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs === null ?
                      '-' : protecaoEficaz.pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                A empresa substitui <strong>imediatamente</strong> o EPI em caso dele estar danificado ou extraviado?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado === null ?
                      '-' : protecaoEficaz.pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                A empresa executa e tem procedimentos internos para caso o EPI apresente irregularidades?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades === null ?
                      '-' : protecaoEficaz.pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class='d-flex justify-content-end mb-3'>
              <b-button
                class='px-3 d-flex align-items-center'
                variant='success'
                @click='mostrarModalSimPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico()'
              >
                <b-icon
                  font-scale='.999'
                  icon='pencil-square'
                  class='mr-2'
                >
                </b-icon>
                <span>Editar</span>
              </b-button>
            </section>

            <section class='divider'></section>
          </section>
        </section>

        <section>
          <section>
            <label>
              O empregado ou trabalhador cumpre todos os itens da NR 06 em seu tópico 6.6?
            </label>

            <b-form-radio-group
              :disabled='disabled'
              @change='updateEffectiveprotection()'
              class='justify-content-between'
              name='pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66'
              v-model='protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66'
            >
              <b-form-radio
                class='py-2 mr-5 '
                :value='true'
              >
                <span
                  :class="protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 == true ? 'selected': 'not-selected'"
                >
                  Sim
                </span>
              </b-form-radio>

              <b-form-radio
                class='py-2 mr-5'
                :value='false'
              >
                <span
                  :class="protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 === false
                    ? 'selected'
                    : 'not-selected'"
                >
                  Não
                </span>
              </b-form-radio>

              <b-form-radio
                class='py-2 mr-5'
                :value='3'
              >
                <span
                  :class="protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 === 3
                    ? 'selected'
                    : 'not-selected'"
                >
                  Não se aplica
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </section>

          <section
            class='mb-3'
            v-if='protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 == true'
          >
            <section class=' pt-2'>
              <label>
                O EPI que o trabalhador usa é somente aquele fornecido pela empresa?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa === null ?
                      '-' : protecaoEficaz.pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                O EPI utilizado pelo trabalhador está alinhado com sua necessidade/finalidade/risco?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco === null ?
                      '-' : protecaoEficaz.pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                O trabalhador está sendo responsável pela limpeza, guarda e conservação do EPI?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI === null ?
                      '-' : protecaoEficaz.pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                O trabalhador matem boa comunicação com a empresa para informar situações em que o EPI estiver
                extraviado, danificado ou qualquer alteração que o torne impróprio para o uso?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso === null ?
                      '-' : protecaoEficaz.pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class=' pt-2'>
              <label>
                O trabalhador cumpre as determinações da empresa quanto ao uso do EPI?:

                <strong>
                  {{
                    protecaoEficaz.pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI === null ?
                      '-' : protecaoEficaz.pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI ? 'Sim' : 'Não'
                  }}
                </strong>
              </label>
            </section>


            <section class='d-flex justify-content-end mb-3'>
              <b-button
                class='px-3 d-flex align-items-center'
                variant='success'
                @click='mostrarModalSimPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66()'
              >
                <b-icon
                  font-scale='.999'
                  icon='pencil-square'
                  class='mr-2'
                >
                </b-icon>
                <span>Editar</span>
              </b-button>
            </section>

            <section class='divider'></section>
          </section>
        </section>

        <section class='d-flex mt-2 align-items-center justify-content-end'>
          <b-button
            @click='resetEffectiveprotection()'
            variant='danger'
            class='px-3 mr-3 d-flex align-items-center'
          >
            <span class='label'>Limpar as respostas que foram selecionadas</span>
          </b-button>

          <b-button
            class='px-5 d-flex align-items-center'
            variant='success'
            @click="hideEffectiveProtection()"
          >
            <i class='fa fa-save'></i>
            <span class='ml-2'>Salvar</span>
          </b-button>
        </section>
      </section>
    </section>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  props: {
    protecaoEficazProps: { type: Object, require: true, default: null },
    functionalityProps: { type: Number, require: true, default: 1 },
    exposicaoPermanenteProps: { type: Object, require: true, default: null },
    userOrProduct: { type: String, require: true, default: 'user' },
    disabled: { type: Boolean, require: false, default: false },
  },
  data() {
    return {
      protecaoEficaz: {
        pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico: null,
        pergunta_1_1_OEPCFuncionandoAdequadamente: null,
        pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia: null,
        pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao: null,
        pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico: null,
        pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA: null,
        pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI: null,
        pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco: null,
        pergunta_2_4_fornecimentoEPISempreRegistrado: null,
        pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI: null,
        pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs: null,
        pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado: null,
        pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades: null,
        pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66: null,
        pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa: null,
        pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco: null,
        pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI: null,
        pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso: null,
        pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI: null
      },
      exposicaoPermanente: {
        frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade: null,
        trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos: null,
        trabalhadorexecutaAtividadeAgentesQuimicosAnalisados: null
      },
      selectedActivities: [],
      functionalities: {
        home: 1,
        questionsPermanentExhibition: 2,
        questionsEffectiveProtection: 3
      },
      functionality: 1,
      home: 1,
      questionsPermanentExhibition: 2,
      questionsEffectiveProtection: 3,
      logs: new Set()
    }
  },
  methods: {
    ...mapActions('effectiveProtection', {
      handleCreateEffectiveprotection: 'handleCreateEffectiveprotection',
      handleUpdateEffectiveprotection: 'handleUpdateEffectiveprotection',
      handleGetEffectiveprotection: 'handleGetEffectiveprotection',
      handleReset: 'handleReset'
    }),
    ...mapActions('permanentExhibition', {
      handleCreatePermanentExhibition: 'handleCreatePermanentExhibition',
      handleUpdatePermanentExhibition: 'handleUpdatePermanentExhibition',
      handleGetPermanentExhibition: 'handleGetPermanentExhibition',
      handleResetPermanentExhibition: 'handleResetPermanentExhibition'
    }),
    ...mapActions('userActionLogs', {
      createUserActionsLogs: 'createUserActionsLogs'
    }),

    setFunctionalities(functionality) {
      this.functionality = this.functionalities[functionality]
    },

    hideEffectiveProtection() {
      this.$refs['effective-protection-modal'].hide()
    },

    hideQuidanceOnTopic() {
      this.$refs.guidanceOnTopic.hide()
    },

    showQuidanceOnTopic() {
      this.$refs.guidanceOnTopic.show()
    },

    showEffectiveProtectionQuidance() {
      this.$refs.effectiveProtectionQuidance.show()
    },

    hideEffectiveProtectionQuidance() {
      this.$refs.effectiveProtectionQuidance.hide()
    },

    async showModal() {
      this.functionality = this.home
      this.protecaoEficaz = {
        pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico: null,
        pergunta_1_1_OEPCFuncionandoAdequadamente: null,
        pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia: null,
        pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao: null,
        pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico: null,
        pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA: null,
        pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI: null,
        pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco: null,
        pergunta_2_4_fornecimentoEPISempreRegistrado: null,
        pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI: null,
        pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs: null,
        pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado: null,
        pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades: null,
        pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66: null,
        pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa: null,
        pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco: null,
        pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI: null,
        pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso: null,
        pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI: null
      };

      if (this.protecaoEficazProps && this.protecaoEficazProps._id) {
        this.protecaoEficaz = this.protecaoEficazProps
      }

      this.exposicaoPermanente = {
        frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade: null,
        trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos: null,
        trabalhadorexecutaAtividadeAgentesQuimicosAnalisados: null
      }

      if (this.exposicaoPermanenteProps && this.exposicaoPermanenteProps._id) {
        this.exposicaoPermanente = this.exposicaoPermanenteProps
      }
      this.logs.clear()
      this.functionality = this.functionalityProps
    },

    async hideModal() {
      this.functionality = this.home
      await this.$store.dispatch('legalSearch/load', this.user.id)
      if (this.user && this.user.role !== 'admin' && this.logs.size > 0) {
        await this.createUserActionsLogs({ actions: Array.from(this.logs) })
      }
      this.$emit('to-load')
    },

    async updatePermanentExhibition() {
      this.$emit('updatePermanentExhibition', this.exposicaoPermanente)
    },

    async resetPermanentExhibition() {
      this.exposicaoPermanente.frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade = null
      this.exposicaoPermanente.trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos = null
      this.exposicaoPermanente.trabalhadorexecutaAtividadeAgentesQuimicosAnalisados = null
      if (this.userOrProduct === 'user') {
        await this.handleResetPermanentExhibition(this.exposicaoPermanente)
      }
      this.$emit('updatePermanentExhibition', this.exposicaoPermanente)
    },

    async resetEffectiveprotection() {
      this.protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico = null
      this.protecaoEficaz.pergunta_1_1_OEPCFuncionandoAdequadamente = null
      this.protecaoEficaz.pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia = null
      this.protecaoEficaz.pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao = null
      this.protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico = null
      this.protecaoEficaz.pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA = null
      this.protecaoEficaz.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI = null
      this.protecaoEficaz.pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco = null
      this.protecaoEficaz.pergunta_2_4_fornecimentoEPISempreRegistrado = null
      this.protecaoEficaz.pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI = null
      this.protecaoEficaz.pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs = null
      this.protecaoEficaz.pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado = null
      this.protecaoEficaz.pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades = null
      this.protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 = null
      this.protecaoEficaz.pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa = null
      this.protecaoEficaz.pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco = null
      this.protecaoEficaz.pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI = null
      this.protecaoEficaz.pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso = null
      this.protecaoEficaz.pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI = null
      if (this.userOrProduct === 'user') {
        await this.handleReset(this.protecaoEficaz)
      }
      this.$emit('updateEffectiveprotection', this.protecaoEficaz)
    },

    async updateEffectiveprotection() {
      this.$emit('updateEffectiveprotection', this.protecaoEficaz)
    },

    mostrarModalSimPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico() {
      this.$bvModal.show('simPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico')
    },

    fecharModalSimPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico() {
      this.$bvModal.hide('simPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico')
    },

    mostrarModalSimPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico() {
      this.$bvModal.show('simPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico')
    },

    fecharModalSimPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico() {
      this.$bvModal.hide('simPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico')
    },

    mostrarModalSimPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66() {
      this.$bvModal.show('simPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66')
    },

    fecharModalSimPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66() {
      this.$bvModal.hide('simPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66')
    }
  },
  watch: {
    'protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico'() {
      if (this.protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico) {
        this.mostrarModalSimPergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico()
      }
    },

    'protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico'() {
      if (this.protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico == true) {
        this.mostrarModalSimPergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico()
      }
    },

    'protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66'() {
      if (this.protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 == true) {
        this.mostrarModalSimPergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66()
      }
    },
    exposicaoPermanenteProps() {
      this.exposicaoPermanente = this.exposicaoPermanenteProps
    },
    protecaoEficazProps() {
      this.protecaoEficaz = this.protecaoEficazProps
    }
  }
}
</script>

<style lang='scss' scoped>
.effectiveProtection {
  font-family: Montserrat, sans-serif;

  .title {
    color: #022245;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  h1 {
    color: #022245;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .title_internal {
    color: #022245;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .subtitle {
    color: #808080;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.9%;

    a {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #022245;

    .strong {
      font-weight: 700;
    }
  }
}


.selected {
  color: #022245;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.not-selected {
  color: #808080;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.perguntas_1 {
  border-bottom: .5px solid #022245;
}

.btn_export {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}

.divider {
  height: 1px;
  background: #C0C0C0;
  width: 100%;
}

.border-right {
  border-right: 1px dashed #C0C0C0 !important;
}

.btn_close {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #022245;
  cursor: pointer;
  text-decoration: none;
  right: 0;
  top: -3px;
}

.guidanceOnTopic {
  font-family: Montserrat, sans-serif;

  a {
    cursor: pointer;
  }

  .title {
    color: #022245;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-top: 5px;
  }

  p {
    color: #262626;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
    span {
      text-decoration: underline;
    }
  }

  #paragrafo_01, #paragrafo_02 {
    cursor: pointer;
  }
}

ul.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI, .pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco {
  list-style-type: upper-roman;
}
</style>
